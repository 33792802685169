import React from "react";
import { graphql, HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa";
import moment from "moment";
import { KeystoneImage } from "gatsby-image-keystone";
import { Link } from "gatsby";
import { PostType } from "@/types";
import Layout from "@/components/Layout";
import { MdArrowBackIosNew } from "react-icons/md";
import { renderers } from "@/utils/renderers";
import Search from "@/components/Search";
import convertSlug from "@/utils/convertSlug";
import { SEO } from "@/components/Seo";

interface Props {
  data: {
    cms: {
      post: PostType;
    };
  };
  pageContext: any;
}

const PostPage = ({ data, pageContext }: Props) => {
  const { post } = data?.cms;
  const { menus } = pageContext;
  const { id, title, content, contentFull, featureImage, publishDate } = post;

  const socialStyles = `duration-300 ease-in-out hover:fill-theme
      mb-[2rem]`;

  return (
    <Layout title={title} pageContext={menus}>
      <div className="">
        <StaticImage
          className="!absolute top-0 !w-screen"
          src="../../../images/overlay_dark.png"
          alt="overlay"
          placeholder="none"
        />
      </div>
      <div className=" xl:container xl:px-0 px-[5%] pt-[150px]  ">
        <div className="items-center md:flex justify-between">
          <h3 className="lg:text-[48px] text-[32px] ">News</h3>
          <div className=" md:flex flex-wrap items-center justify-end text-darkGray">
            <Link
              className="cursor-pointer relative before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-[0%] before:bg-theme before:transition-width hover:before:left-0 hover:before:w-[100%]"
              to="/"
            >
              Home
            </Link>
            <Link to="/news">
              <span className="relative inline-block w-8 items-center  text-[10px] text-theme ">
                <MdArrowBackIosNew className="rotate-180 mx-auto" />
              </span>
              <span className="cursor-pointer relative before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-[0%] before:bg-theme before:transition-width hover:before:left-0 hover:before:w-[100%]">
                News
              </span>
            </Link>
            <span className="relative inline-block w-8 items-center  text-[10px] text-theme ">
              <MdArrowBackIosNew className="rotate-180 mx-auto" />
            </span>
            {title}
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-[max-content_64%_36%]">
          {/* Socials */}
          <div className="relative mt-[20rem] hidden pr-[2.5rem] lg:block">
            <a href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F">
              <FaFacebookF size={24} className={socialStyles} />
            </a>
            <a href="https://twitter.com/intent/tweet?text=Iron+ore+price+rises+on+disappointing+figures+by+top+producers+-+https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F">
              <FaTwitter size={24} className={socialStyles} />
            </a>
            <a href="https://www.pinterest.com/pin-builder/?url=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F&media=https%3A%2F%2Ffitrade.steppecopper.mn%2Fwp-content%2Fuploads%2F2021%2F07%2Fbull-market-1024x576-1.jpeg&description=Iron+ore+price+rises+on+disappointing+figures+by+top+producers&method=button">
              <FaPinterest size={24} className={socialStyles} />
            </a>
            <a href="https://www.linkedin.com/shareArticle/?mini=true&url=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F&title=Iron%20ore%20price%20rises%20on%20disappointing%20figures%20by%20top%20producers">
              <FaLinkedin size={24} className={socialStyles} />
            </a>
          </div>

          {/* Article */}
          <div className="mt-[4rem] px-[20px] lg:px-0">
            {/* Date */}
            <div className="relative">
              <span className="absolute top-[50%] h-[24px] -translate-y-[50%] border-l-[4px] border-theme"></span>
              <p className="pl-[1rem] text-left text-[14px] font-[700] uppercase">
                POSTED ON {moment(publishDate).format("LL")} IN NEWS
              </p>
            </div>

            {/* Title */}
            <h2 className="mt-[1rem] text-[24px] font-[700] leading-8 lg:text-[48px] lg:leading-[3.5rem]">
              {title}
            </h2>

            {/* Mobile Socials */}
            <div className="my-[2rem] flex lg:hidden">
              <a href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F">
                <FaFacebookF size={24} className="mr-[1rem]" />
              </a>
              <a href="https://twitter.com/intent/tweet?text=Iron+ore+price+rises+on+disappointing+figures+by+top+producers+-+https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F">
                <FaTwitter size={24} className="mr-[1rem]" />
              </a>
              <a href="https://www.pinterest.com/pin-builder/?url=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F&media=https%3A%2F%2Ffitrade.steppecopper.mn%2Fwp-content%2Fuploads%2F2021%2F07%2Fbull-market-1024x576-1.jpeg&description=Iron+ore+price+rises+on+disappointing+figures+by+top+producers&method=button">
                <FaPinterest size={24} className="mr-[1rem]" />
              </a>
              <a href="https://www.linkedin.com/shareArticle/?mini=true&url=https%3A%2F%2Ffitrade.steppecopper.mn%2Fnews%2Firon-ore-price-rises-on-disappointing-figures-by-top-producers%2F&title=Iron%20ore%20price%20rises%20on%20disappointing%20figures%20by%20top%20producers">
                <FaLinkedin size={24} className="mr-[1rem]" />
              </a>
            </div>

            {/* Article */}
            <div className="lg:mt-[3rem]">
              {featureImage && (
                <KeystoneImage
                  className="mb-[2rem]"
                  baseUrl="fortune"
                  image={{
                    alt: featureImage.id,
                    width: 815,
                    height: featureImage.height * (815 / featureImage.width),
                    key: `${featureImage.id}.${featureImage.extension}`,
                  }}
                  layout="fullWidth"
                  alt={featureImage.id}
                />
              )}

              <div>
                <div className="text-[18px] text-darkGray leading-9 ">
                  <DocumentRenderer
                    document={contentFull.document}
                    renderers={renderers}
                  />
                </div>
              </div>
            </div>

            {/* Switch posts */}
            <div
              className={`mt-[1rem]  mb-[4rem] border-t-[1px] border-lightGray pt-[1rem] text-right flex  ${
                pageContext.previous ? "justify-between " : "justify-end"
              }`}
            >
              {pageContext.previous && (
                <Link
                  className=" float-left font-[700] text-darkGray duration-300 ease-in-out hover:text-black disabled:text-lightGray"
                  to={
                    pageContext.previous &&
                    `/news/${convertSlug(pageContext.previous)}`
                  }
                >
                  Previous post
                </Link>
              )}
              {pageContext.next && (
                <Link
                  to={`/news/${convertSlug(pageContext.next)}`}
                  className="font-[700] float-right text-darkGray duration-300 ease-in-out hover:text-black disabled:text-lightGray"
                >
                  Next post
                </Link>
              )}
            </div>
          </div>

          {/* Recents */}
          <Search pageContext={pageContext} />
        </div>
      </div>
    </Layout>
  );
};

// export const Head: HeadFC = (props: any) => (
//   <SEO title={props.data.cms.post.title} />
// );
export const query = graphql`
  query PostById($id: ID) {
    cms {
      post(where: { id: $id }) {
        title
        id
        publishDate
        localization {
          code
        }
        content {
          document
        }
        contentFull {
          document
        }
        featureImage {
          extension
          filesize
          height
          id
          width
        }
      }
    }
  }
`;

export default PostPage;
