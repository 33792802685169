import * as React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { PostType } from "@/types";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import moment from "moment";
import { Link } from "gatsby";
import convertSlug from "../../../utils/convertSlug";
import { KeystoneImage } from "gatsby-image-keystone";
interface Props {
  post: PostType;
}

const Card = ({ post }: Props) => {
  const slug = convertSlug(post.title);
  return (
    <div className="bg-white relative p-[1rem] shadow-2xl border-b-[1px] border-[#f5f5f5] grid grid-rows-1]  grid-flow-col w-auto min-w-[240px] ">
      {/* <div className="relative grid grid-cols-[max-content_fit] gap-3 bg-white p-[1rem] shadow-lg lg:grid-cols-[30%_70%]"> */}
      <div className="relative h-max ">
        <div className="relative group cursor-pointer ">
          <div className="group-hover:p-0 relative z-10">
            {post.featureImage && (
              <KeystoneImage
                baseUrl="fortune"
                image={{
                  alt: post.featureImage.id,
                  width: 72,
                  height: 72,
                  key: `${post.featureImage.id}.${post.featureImage.extension}`,
                }}
                layout="fixed"
                alt={post.featureImage.id}
              />
            )}
          </div>
          <Link to={`/news/${slug}`}>
            <div className=" z-20 group-hover:opacity-80 absolute top-0 left-0 h-full w-full  bg-black ease-out duration-300 opacity-0">
              {/* <span className="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-fullpacity-80"></span> */}
              <AiOutlinePlus
                color="white"
                className="absolute left-[50%] top-[50%] z-20 -translate-x-[50%] -translate-y-[50%] opacity-100"
              />
            </div>
          </Link>
        </div>
      </div>
      <div className=" pl-4 w-full box-border">
        <Link
          to={`/news/${slug}`}
          className="relative cursor-pointer break-words font-[700] duration-300 tracking-[0.5px] ase-in-out hover:text-theme text-[18px] leading-[26px]"
        >
          {post?.title}
        </Link>

        {/* Date */}
        <p className="mt-[0.5rem] text-[16px] text-gray">
          {moment(post?.publishDate).format("MMM DD, YYYY")}
        </p>
      </div>
    </div>
  );
};
export default Card;
