import usePosts from "@/hooks/usePosts";
import * as React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import CardList from "./CardList";

interface Props {
  pageContext: any;
}

const Search = ({ pageContext }: Props) => {
  const { id } = pageContext;
  const posts = usePosts().filter((post: any) => post.id !== id);
  const [searchField, setSearchField] = React.useState("");
  const [filteredPosts, setfilteredPosts] = React.useState([]);
  const [showRecent, setShowRecent] = React.useState(true);
  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setSearchField(e?.target.value);
  };

  const recentPost = posts.slice(0, 3);

  React.useEffect(() => {
    if (searchField.length !== 0) {
      setShowRecent(false);
      setfilteredPosts(
        posts.filter((post: any) => {
          return post.title.toLowerCase().includes(searchField.toLowerCase());
        })
      );
    } else {
      setShowRecent(true);
      setfilteredPosts([]);
    }
  }, [searchField]);

  return (
    <div>
      {/* Recents */}
      <div className="pt-[70px] lg:ml-[2.7rem] lg:px-[4.4rem]">
        {/* Search */}
        <div className="relative mb-[3rem]">
          <input
            type="text"
            className="w-full border-[1px] border-lightGray p-[1rem] text-[16px] text-darkGray outline-none duration-300 ease-in-out hover:border-darkGray"
            placeholder="Search..."
            onChange={onSearchChanged}
          />
          <button className="absolute right-[1rem] top-[50%] -translate-y-[50%]">
            <AiOutlineSearch size={20} />
          </button>
        </div>

        <CardList
          filteredPosts={showRecent ? recentPost : filteredPosts}
          showRecentPosts={showRecent}
          searchField={searchField}
        />
      </div>
    </div>
  );
};

export default Search;
