import React from "react";
import { DocumentRendererProps } from "@keystone-6/document-renderer";

export const renderers: DocumentRendererProps["renderers"] = {
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
    italic: ({ children }) => {
      return <em>{children}</em>;
    },
    link: ({ children, href }) => {
      return (
        <a
          className="text-theme underline italic"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
  block: {
    paragraph: ({ children }) => {
      return <p className="mb-6 text-[18px] font-[500]">{children}</p>;
    },
    blockquote: ({ children }) => {
      return (
        <blockquote className="mb-6 border-l-4 border-theme text-black">
          <div className="text-[28px] leading-[42px]  pl-4">{children}</div>
        </blockquote>
      );
    },
    list: ({ children, type }) => {
      if (type === "ordered") {
        return (
          <ol className="list-inside">
            {" "}
            {children.map((child, index) => (
              <li key={Math.random() * 1000} className="mb-4">
                {child}
              </li>
            ))}
          </ol>
        );
      } else {
        return (
          <ul className="list-disc list-inside cursor-pointer [&>*]:py-0.5 [&>*]:leading-[32px] ">
            {children.map((child) => (
              <li key={Math.random() * 1000} className="mb-4">
                {child}
              </li>
            ))}
          </ul>
        );
      }
    },
    heading: ({ children, level }) => {
      if (level == 4) {
        return (
          <h4 className="mb-6 text-[25px] text-dark font-[500]">{children}</h4>
        );
      } else {
        return (
          <h4 className="mb-6 text-[32px] leading-[48px]  text-dark font-[500]">
            {children}
          </h4>
        );
      }
    },
  },
};
