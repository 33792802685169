import * as React from "react";
import { PostType } from "@/types";
import Card from "../Card";

interface Props {
  filteredPosts?: any;
  showRecentPosts?: boolean;
  searchField?: string;
}
const CardList = ({ filteredPosts, showRecentPosts, searchField }: Props) => {
  return (
    <div className="">
      <div className="relative mb-8">
        <p className="text-[18px] font-[700]">
          {showRecentPosts ? "Recent posts" : "Search results"}
        </p>

        <div className="flex">
          <div
            className={`relative w-6 bg-theme h-[2px] rounded-[1px] before:absolute before:left-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-[#e0e0e0] before:transition-width before:duration-200 ${
              showRecentPosts ? "" : "before:w-full before:right-0"
            }`}
          ></div>
          <div
            className={`relative w-8 bg-[#e0e0e0]  ml-[1.5px] rounded-[1px] before:absolute before:left-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-theme before:transition-width ${
              showRecentPosts ? "" : "before:w-full before:right-0"
            }`}
          ></div>
        </div>
      </div>
      {!showRecentPosts && filteredPosts.length === 0 && (
        <div className="text-black mb-8 ">
          <span className="text-xl  text-darkGray italic">No results for </span>{" "}
          <span className="font-bold text-xl">"{searchField}"</span>
        </div>
      )}
      {filteredPosts.map((post: PostType) => (
        <Card key={post.id} post={post} />
      ))}
    </div>
  );
};
export default CardList;
